import "./app.scss"

const scripts = import.meta.glob('./components/**/*.js')

for (const path in scripts) {
    scripts[path]().then(
        module => {
            const {default: init} = module
        }
    )
}

